/* eslint-disable no-plusplus */
import { IconTypeProp } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

type ProgramKeys =
  | 'show_closings'
  | 'show_closings_or_trade_ins'
  | 'show_portal_dashboard'
  | 'show_referrals'
  | 'show_home_loans'
  | 'show_hlss'
  | 'show_metrics'
  | 'show_trade_ins'
  | 'has_team'
  | 'is_agent'
  | 'is_coordinator';

type INavbarItemType = 'link' | 'profile' | 'dropdown' | 'message';

export interface INavbar {
  id: number;
  type: INavbarItemType;
  text?: string;
  url?: string | ((flags?: { enableNewReferrals?: boolean }) => string);
  active?: boolean;
  someProgramKey?: ProgramKeys[];
  everyProgramKey?: ProgramKeys[];
  notActivable?: boolean;
  notRenderMobile?: boolean;
  openInNewTab?: boolean;
  icon?: IconTypeProp;
  items?: {
    id: number;
    type: INavbarItemType;
    text?: string;
    url?: string | ((flags?: { enableNewReferrals?: boolean }) => string);
    active?: boolean;
    someProgramKey?: ProgramKeys[];
    everyProgramKey?: ProgramKeys[];
    openInNewTab?: boolean;
  }[];
}

export const endabledPrograms = [
  'Trade In',
  'HL Simple Sale',
  'Cash Offer',
  'Closing Services',
];

export const disabledPropgrams = [
  'Fully Funded',
  'Home Loans',
  'Referrals',
  'Cash Close',
];

export function renderNavbar(
  agentPrograms: AgentType['marketplace_program_agents']
): INavbar[] {
  let uniqueId = 0;

  return [
    {
      id: ++uniqueId,
      type: 'link',
      text: 'Dashboard',
      url: '/dashboard',
      everyProgramKey: ['show_portal_dashboard'],
    },
    {
      id: ++uniqueId,
      type: 'link',
      text: 'Referrals',
      url: `/referrals`,
      everyProgramKey: ['show_referrals'],
    },
    {
      id: ++uniqueId,
      type: 'link' as const,
      text: 'Transactions',
      url: '/transactions',
      everyProgramKey: ['show_closings_or_trade_ins' as const],
    },
    {
      id: ++uniqueId,
      type: 'message',
      text: 'Messages',
      url: `/messages`,
      notActivable: true,
    },
    {
      id: ++uniqueId,
      type: 'dropdown',
      text: 'Account',
      items: [
        {
          id: ++uniqueId,
          type: 'link' as const,
          text: 'Settings',
          url: '/settings',
        },
        {
          id: ++uniqueId,
          type: 'link',
          text: 'Help Center',
          url: 'https://help.homelight.com/',
          openInNewTab: true,
        },
        {
          id: ++uniqueId,
          type: 'link',
          text: 'Marketing Resources',
          url: 'https://homelight.com/marketing',
          openInNewTab: true,
        },
      ],
    },
  ] as INavbar[];
}
