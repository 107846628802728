import dayjs from 'dayjs';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import DOMPurify from 'dompurify';
import { AsYouType } from 'libphonenumber-js';

import {
  emailRegex,
  phoneRegex,
  ssnRegex,
  urlRegex,
} from '@hl-portals/helpers';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

export const firstNameShortener = (name: string): string =>
  name
    .split(' ')
    .reduce(
      (acc, val, idx) => (idx === 0 ? `${val[0]}.` : `${acc} ${val}`),
      ''
    );

export const firstName = (name?: string) => {
  if (!name) {
    return '';
  }
  return name.split(' ')[0];
};

export const removeSpaces = (str: string): string =>
  str.replace(/\s+/g, ' ').trim();

export const getInitials = (name: string): string =>
  removeSpaces(name)
    .split(' ')
    .reduce((acc, val) => `${acc}${val[0]}`, '');

export const capitalizeWord = (str: string): string =>
  `${str[0].toUpperCase()}${str.substring(1)}`;

export const capitalizeWords = (str: string): string => {
  if (!str) return '';
  return str.split(' ').reduce((acc, val, idx) => {
    if (val.length === 0) {
      return `${acc}${idx > 0 ? ' ' : ''}`;
    }
    const accu = idx === 0 ? acc : `${acc} `;
    return `${accu}${capitalizeWord(val)}`;
  }, '');
};

export const capitalizeWords2 = (str: string) => {
  return str
    .split(' ')
    .map((w) => `${w[0].toUpperCase()}${str.substring(1)}`)
    .join(' ');
};

export const capitalize = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      const [firstLetter, ...rest] = word.split('');
      return `${firstLetter.toUpperCase()}${rest.join('').toLowerCase()}`;
    })
    .join(' ');
};

export const formatPercentage = (value: number) => `${value.toFixed(1)}%`;

export const formatCurrency = ({
  value,
  raw,
}: {
  value: number | string;
  raw?: boolean;
}): string => {
  if (value === null) return '';
  if (typeof value === 'string' && !value) value = 0;
  if (`${value}`.match(/[^0-9]{1}$/))
    return value.toString().substr(0, value.toString().length - 1);

  const inputValue =
    typeof value === 'string' ? parseInt(value.replace(/,/g, ''), 10) : value;

  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
    .format(inputValue)
    .replace('$', '');

  if (raw) {
    return formattedValue;
  }

  return `$${formattedValue}`;
};

export const isInternalLink = (url: string): boolean => {
  const internalRegex = /(^https?:\/\/[A-z0-9.]*homelight\.com|^\/)/i;
  if (!url.match(internalRegex)) {
    return false;
  }

  const internalExceptionsRegex =
    /^https?:\/\/(help\.homelight\.com|(www\.)?homelight\.com\/blog)/i;

  if (url.match(internalExceptionsRegex)) {
    return false;
  }

  return true;
};

export const ellipsis = (str = '', max = 10, suffix = '...'): string => {
  if (str.length < max) return str;

  return str.slice(0, max) + suffix;
};

export const formatPhoneNumber = (str: string): string => {
  if (!str) return '';
  if (str[0] === '1') return '';
  if (str.length === 4) return str.replace(/\D+/, '');
  const phoneFormatter = new AsYouType('US');
  if (str.length > 14) return str.substr(0, 14);
  return phoneFormatter.input(str);
};

export const formatSsn = (str: string): string => {
  const newStr = str.split('-').join('');
  const parts = [
    newStr.slice(0, 3),
    newStr.slice(3, 5),
    newStr.slice(5, 9),
  ].filter(Boolean);
  return parts.join('-');
};

export const removePhoneFormat = (phoneString: string): string => {
  return phoneString?.replace(/\D/g, '');
};

export const getFirstLastName = (
  name: string
): { firstName: string; lastName: string } => {
  const nameArray = name.split(' ');
  const [firstName = ''] = nameArray;
  const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : '';
  return {
    firstName,
    lastName,
  };
};

export const isFullName = (name: string): boolean =>
  name.trim().split(' ').length >= 2;

export const isValidEmail = (email: string): RegExpMatchArray | null =>
  (email || '').match(emailRegex);

export const isValidPhone = (phone: string): boolean => {
  if (phone === '(___) ___-____') return false;
  const regex = new RegExp(phoneRegex);
  return regex.test(phone);
};

export const isValidUrl = (url: string): RegExpMatchArray | null =>
  (url || '').match(urlRegex);

export const isValidSsn = (ssn: string): RegExpMatchArray | null =>
  (ssn || '').match(ssnRegex);

export const formatDate = ({
  dateString,
  dateFormat,
}: {
  dateString: string;
  dateFormat?: string;
}) => dayjs(dateString).format(dateFormat || 'MMM DD, YYYY');

export const formatDateTime = ({
  dateString,
  dateFormat,
  timeFormat,
}: {
  dateString: string;
  dateFormat?: string;
  timeFormat?: string;
}) => {
  const formattedDate = dayjs(dateString).format(dateFormat || 'MMM DD, YYYY');
  const formattedTime = dayjs(dateString).format(timeFormat || 'h:mm A z');
  return `${formattedDate} at ${formattedTime}`;
};

export const formatTime = ({
  dateString,
  showTimezone,
}: {
  dateString: string;
  showTimezone?: boolean;
}) => dayjs(dateString).format(`h:mm A ${showTimezone ? 'z' : ''}`);

export const sanitizeString = (string: string) => DOMPurify.sanitize(string);

export const formatMultilineText = (string: string) => ({
  __html: sanitizeString(string.replace(/\n/g, '<br/>')),
});

export const stringMatchesAny = (
  string: string,
  arr: Array<string>,
  options?: { strict?: boolean }
): boolean => {
  const { strict = true } = options || {};

  return Boolean(
    arr.filter((val) => (strict ? string === val : string.includes(val))).length
  );
};

export const hideEmailAddress = (email: string) => {
  const parts = email.split('@');

  if (parts.length !== 2) {
    return email;
  }

  const localPart = parts[0];
  const domain = parts[1];

  if (localPart.length <= 1) {
    return email;
  }

  const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 1);

  return maskedLocalPart + '@' + domain;
};

export const hideMaskedPhoneNumber = (maskedPhoneNumber: string) => {
  const cleanedNumber = maskedPhoneNumber.replace(/\D/g, '');

  if (cleanedNumber.length < 10) {
    return maskedPhoneNumber;
  }

  const lastFourDigits = cleanedNumber.slice(-4);
  return `(***) ***-${lastFourDigits}`;
};

export function formatRelativeDate(dateStr: string) {
  dayjs.extend(relativeTime);
  return dayjs(dateStr).format('dddd, MMMM D, YYYY');
}

export function normalizePhoneNumber(phone: string) {
  return phone?.replace(/[^0-9]/g, '');
}
