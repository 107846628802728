/* stylelint-disable selector-type-no-unknown */
import {
  DisplayProps,
  FlexboxProps,
  GridProps,
  display,
  flexbox,
  grid,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { media, paragraphHelper } from '@hl-portals/helpers';

interface NavbarWrapperProps {
  isOpen: boolean;
}

export const NavbarWrapper = styled.nav<NavbarWrapperProps>`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 60px)' : '0')};
  overflow: hidden;
  transition: height 0.2s ease-out;
  margin-top: 1px;
  ${media.desktop`
    overflow: visible;
    height: auto;
    width: auto;
    display: block;
    margin: 0;
  `}
`;

export const NavbarProfile = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

export const NavbarAvatar = styled.img`
  display: block;
  border-radius: 50%;
  margin-right: 10px;
`;

export const NavbarProfileInfo = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > a {
    display: block;
    color: ${({ theme }) => theme.colors.electricBlue};
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
  }
`;

interface INavbarItem {
  active?: boolean;
  isElite?: boolean;
  cursor?: 'pointer' | 'auto';
  isSearchOpen?: boolean;
  withBorderTop?: boolean;
  indent?: boolean;
}

export const NavbarItem = styled.li<
  INavbarItem & DisplayProps & GridProps & FlexboxProps
>`
  ${({ theme, active, isElite, isSearchOpen, withBorderTop, indent }) => css`
    box-sizing: border-box;
    line-height: 60px;
    font-weight: 300;
    width: 100%;
    ${indent ? 'margin-left: 20px !important;' : ''}
    ${withBorderTop ? `border-top: 1px solid ${theme.colors.coolGray5};` : ''}
    ${media.desktop`
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      line-height: ${active ? '57' : '60'}px;
      width: auto;
      flex-grow: 1;
      position: relative;
      margin: 0 20px;
      ${active && !isSearchOpen
        ? `border-bottom: 3px solid ${theme.colors.darkBlue};`
        : ''
      }
      ${isElite && active && !isSearchOpen
        ? `border-bottom: 3px solid ${theme.colors.electricBlue};`
        : ''
      }
    `}
  > a,
  > span {
      color: ${isElite ? theme.colors.silver : theme.colors.darkBlue};
      ${paragraphHelper({ type: 'text-small', theme })}
      ${active ? `color: ${theme.colors.darkBlue};` : ''};
      ${active && isElite ? `color: ${theme.colors.white};` : ''};
      ${active ? 'font-weight: 600;' : ''}
      text-decoration: none;

      ${media.desktop`
        &:hover {
          color: ${theme.colors.electricBlue};
          &::before,
          &::after {
            background-color: ${theme.colors.electricBlue};
          }
        }
      `}
    }
    a svg {
      display: none;
      cursor: pointer;
      fill: ${isElite ? theme.colors.silver : theme.colors.coolGray3};
      width: 20px;
      height: 20px;

      ${media.desktop`
        display: block;
          &:hover {
            fill: ${theme.colors.electricBlue};
         }
      `}
    }
  `}

  ${display}
  ${flexbox}
  ${grid}
`;

export const NavbarList = styled.ul`
  display: flex;
  margin-right: 30px;
  flex-wrap: wrap;
  ${media.desktop`
  flex-wrap: nowrap;
    margin-right: 5px;
  `}
  > ${NavbarItem}:first-child {
    margin-left: 0;
  }
  > ${NavbarItem}:last-child {
    margin-right: 0;
  }
`;
