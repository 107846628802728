import styled, { createGlobalStyle, css } from 'styled-components';

import { Box, Button, SimpleLink } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export interface HeaderWrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  isSearchOpen?: boolean;
  isElite?: boolean;
}

export const AdjustedBodyStyle = createGlobalStyle<{ isSearchOpen: boolean }>`
  body {
    ${({ isSearchOpen }) =>
      isSearchOpen &&
      css`
        position: fixed;
        top: 61px;
        left: 0;
        right: 0;
      `}
  }
`;

export const HeaderWrapper = styled.header<HeaderWrapperProps>`
  ${({ theme, isOpen, isElite }) => css`
    flex-direction: row;
    flex-grow: 1;
    border-bottom: 1px solid
      ${isElite ? theme.colors.stratos : theme.colors.coolGray4};
    padding-bottom: ${isOpen ? 'calc(100vh - 60px)' : '0'};
    background-color: ${isOpen ? theme.colors.coolGray4 : theme.colors.white};
    ${isElite && `background-color: ${theme.colors.stratos}`};
    transition: background-color 0.2s ease-out, padding-bottom 0.2s ease-out;
    z-index: 30;
    padding: 0 20px;
    position: sticky;
    top: 0;
    ${media.desktop`
      padding-bottom: 0;
      flex-wrap: nowrap;
      background-color: ${isElite ? theme.colors.stratos : theme.colors.white};
    `}
    ${media.print`
      display: none;
    `}
  `}

  ${({ isSearchOpen }) =>
    isSearchOpen &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
    `}
`;

export const ImpersonationHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: #bc3030;
`;

export const UnimpersonateButton = styled(Button)`
  margin-left: 20px;
  background-color: initial;
  border: 1px solid transparent;
  border-color: white;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  background: transparent;
  font-weight: bold;
`;

export const HeaderContainer = styled(Box)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: auto;
`;

const defaultBurgerIcon = css`
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

interface HeaderMenuToggleProps {
  isOpen: boolean;
  isElite?: boolean;
}

export const HeaderMenuToggle = styled.button<HeaderMenuToggleProps>`
  position: relative;
  width: 24px;
  height: 18px;
  padding: 0;
  margin-left: 20px;
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;

  ${media.desktop`
    margin-left: 0;
    display: none;
  `}

  span {
    ${defaultBurgerIcon}
    background-color: ${({ theme, isElite }) =>
      isElite ? theme.colors.white : theme.colors.black};
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    content: '';
    ${defaultBurgerIcon};
    background-color: ${({ theme, isElite }) =>
      isElite ? theme.colors.white : theme.colors.black};
    top: 0;
  }
  &::after {
    content: '';
    ${defaultBurgerIcon};
    background-color: ${({ theme, isElite }) =>
      isElite ? theme.colors.white : theme.colors.black};
    bottom: 0;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          span {
            opacity: 0;
          }
          &:before {
            transform: translate(0px, 8px) rotate(45deg);
          }
          &:after {
            transform: translate(0px, -8px) rotate(-45deg);
          }
        `
      : ''}
`;

export const AlertButton = styled(Button)<{ isElite: boolean }>`
  ${({ theme, isElite }) => css`
    background-color: ${isElite
      ? theme.colors.electricBlue
      : theme.colors.darkBlue};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.white};
    transition: all 0.2s ease-out;

    &:hover,
    &:active {
      background-color: ${isElite
        ? theme.colors.electricBlue
        : theme.colors.darkBlue};
      opacity: 0.8;
      border: 1px solid ${theme.colors.white};
    }
  `}
`;

export const NewAlertButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.red100};
    color: ${theme.colors.red700};
    border: 1px solid ${theme.colors.red700};
    transition: all 0.2s ease-out;

    &:hover,
    &:active {
      background-color: ${theme.colors.red100};
      opacity: 0.8;
      border: 1px solid ${theme.colors.red700};
    }
  `}
`;

export const AlertLink = styled(SimpleLink)<{ color?: string }>`
  ${({ theme, color }) => css`
    color: ${color ? color : theme.colors.red800};
    text-decoration: underline;
  `}
`;
