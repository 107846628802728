import {
  DisplayProps,
  FlexboxProps,
  PositionsProps,
  SizingProps,
  SpacingProps,
} from '@mui/system';

import { forwardRef } from 'react';

import { Colors, theme } from '@hl-portals/constants';

import {
  AddUser,
  AddUserFilled,
  AddressBook,
  AlignJustifyIcon,
  AngleDownIcon,
  AngleDownIconBlue,
  AngleDownIconSolid,
  AngleLeftIcon,
  AngleRightIcon,
  AngleRightIconBlue,
  AngleUpIcon,
  AngleUpIconBlue,
  ArrowIcon,
  ArrowLongRightIcon,
  ArrowRight,
  ArrowToBottomIcon,
  ArrowTriangleRightIcon,
  AtIcon,
  AwardSolidIcon,
  BadgeCheckIcon,
  Bank,
  Bathroom,
  Bedroom,
  Briefcase,
  Bullseye,
  Calculator,
  CalculatorSolid,
  CalendarIcon,
  CalendarSolidIcon,
  CardViewIcon,
  CaretDown,
  CheckCircleIcon,
  CheckCircleLightIcon,
  CheckIcon,
  ClearButton,
  ClockIcon,
  CloseIcon,
  CloseIconSlim,
  CloseIconSlimLight,
  CloudUploadIcon,
  CommentIcon,
  CommentLineIcon,
  CopyIcon,
  DocumentIcon,
  Dollar,
  DollarCircle,
  DownloadIcon,
  DownloadIconLine,
  EditDocument,
  EmailIcon,
  EnvelopeIcon,
  EqualIcon,
  ExclamationCircleIcon,
  ExclamationCircleIconLight,
  ExternalLinkIcon,
  ExternalLinkSolidIcon,
  EyeIcon,
  EyeSlashIcon,
  FacebookIcon,
  FacebookIconSlim,
  FileContractIcon,
  FileContractSolidIcon,
  FileIcon,
  FileSignatureIcon,
  FilterIcon,
  FrontOfProperty,
  GraphicRiseIcon,
  HLCashOfferIcon,
  HLCashOfferSolidIcon,
  HLClosingServicesIcon,
  HLSimpleSaleIcon,
  HLTradeInIcon,
  HLTradeInSolidIcon,
  HandshakeAngledIcon,
  HandshakeIcon,
  HomeHeartIcon,
  HomeIcon,
  HomeOutlineIcon,
  ImageIcon,
  ImagesIcon,
  InfoIcon,
  InfoSolidIcon,
  InstagramIcon,
  Kitchen,
  LandmarkMagnifyingGlass,
  LightbulbIcon,
  Link,
  LinkedInIcon,
  ListIcon,
  LivingRoom,
  LockIcon,
  LockSolidIcon,
  LongArrow,
  MapMarker,
  MapMarkerSolid,
  MedalIcon,
  MedalSolidIcon,
  MessageIcon,
  MobilePhone,
  MoneyCheckIcon,
  MoreVertical,
  OfferIcon,
  Outdoor,
  PencilIcon,
  PencilIcon2,
  Phone,
  PhoneOutline,
  PinterestIcon,
  PlayCircleIcon,
  PlusIcon,
  PropertyExterior,
  QuestionCircleIcon,
  QuestionCircleLineIcon,
  RSSIcon,
  ReferralsBlocked,
  ReferralsIcon,
  SearchIcon,
  SellerSignIcon,
  SendIcon,
  SignpostIcon,
  SimpleDownloadIcon,
  SortASC,
  SortDSC,
  SortIcon,
  SortIdle,
  StarLightIcon,
  StarSolidIcon,
  SuccessCircleFilled,
  Tasks,
  TextBubbleIcon,
  TimesCircleIcon,
  TimesIcon,
  Trash,
  TrophyIcon,
  TwitterIcon,
  UniqueFeatures,
  UploadDocumentIcon,
  UploadedDocumentIcon,
  UploadingDocumentIcon,
  UserCard,
  UserCardSolid,
  UserCheckIcon,
  UserHeadset,
  UserIcon,
  UserIconSolid,
  UserSettings,
  WarningCircleFilled,
  YoutubeIcon,
} from './icons';
import IconWrapper from './styles';

export * from './icons';

export const ICONS = {
  at: AtIcon,
  cashOffer: HLCashOfferIcon,
  cashOfferSolid: HLCashOfferSolidIcon,
  closingServices: HLClosingServicesIcon,
  briefcase: Briefcase,
  bullseye: Bullseye,
  tradeIn: HLTradeInIcon,
  tradeInSolid: HLTradeInSolidIcon,
  simpleSale: HLSimpleSaleIcon,
  addUser: AddUser,
  addUserFilled: AddUserFilled,
  arrow: ArrowIcon,
  arrowTriangleRight: ArrowTriangleRightIcon,
  arrowToBottom: ArrowToBottomIcon,
  search: SearchIcon,
  message: MessageIcon,
  moreVertical: MoreVertical,
  user: UserIcon,
  userSolid: UserIconSolid,
  userSettings: UserSettings,
  userCheck: UserCheckIcon,
  close: CloseIcon,
  closeSlim: CloseIconSlim,
  closeSlimLight: CloseIconSlimLight,
  times: TimesIcon,
  timesCircle: TimesCircleIcon,
  comment: CommentIcon,
  commentLine: CommentLineIcon,
  download: DownloadIcon,
  downloadLine: DownloadIconLine,
  simpleDownload: SimpleDownloadIcon,
  send: SendIcon,
  file: FileIcon,
  fileContract: FileContractIcon,
  fileContractSolid: FileContractSolidIcon,
  fileSignature: FileSignatureIcon,
  filter: FilterIcon,
  calculator: Calculator,
  calculatorSolid: CalculatorSolid,
  cloudUpload: CloudUploadIcon,
  check: CheckIcon,
  checkCircle: CheckCircleIcon,
  checkCircleLight: CheckCircleLightIcon,
  alignJustify: AlignJustifyIcon,
  signpost: SignpostIcon,
  handshake: HandshakeIcon,
  handshakeAngled: HandshakeAngledIcon,
  info: InfoIcon,
  infoSolid: InfoSolidIcon,
  uploadDocument: UploadDocumentIcon,
  uploadingDocument: UploadingDocumentIcon,
  uploadedDocument: UploadedDocumentIcon,
  plus: PlusIcon,
  equal: EqualIcon,
  starSolid: StarSolidIcon,
  starLight: StarLightIcon,
  questionCircle: QuestionCircleIcon,
  questionCircleLine: QuestionCircleLineIcon,
  externalLink: ExternalLinkIcon,
  externalLinkSolid: ExternalLinkSolidIcon,
  trophy: TrophyIcon,
  angleUp: AngleUpIcon,
  angleUpBlue: AngleUpIconBlue,
  angleDown: AngleDownIcon,
  angleDownBlue: AngleDownIconBlue,
  angleDownSolid: AngleDownIconSolid,
  angleRight: AngleRightIcon,
  angleRightBlue: AngleRightIconBlue,
  angleLeft: AngleLeftIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
  sellerSign: SellerSignIcon,
  document: DocumentIcon,
  badgeCheck: BadgeCheckIcon,
  playCircle: PlayCircleIcon,
  list: ListIcon,
  cardView: CardViewIcon,
  copy: CopyIcon,
  pencil: PencilIcon,
  pencil2: PencilIcon2,
  landmarkMagnifyingGlass: LandmarkMagnifyingGlass,
  lightbulb: LightbulbIcon,
  image: ImageIcon,
  images: ImagesIcon,
  home: HomeIcon,
  homeOutline: HomeOutlineIcon,
  calendar: CalendarIcon,
  calendarSolid: CalendarSolidIcon,
  facebook: FacebookIcon,
  facebookSlim: FacebookIconSlim,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  pinterest: PinterestIcon,
  instagram: InstagramIcon,
  rss: RSSIcon,
  youtube: YoutubeIcon,
  email: EmailIcon,
  clearButton: ClearButton,
  envelope: EnvelopeIcon,
  tasks: Tasks,
  textBubble: TextBubbleIcon,
  exclamationCircle: ExclamationCircleIcon,
  exclamationCircleLight: ExclamationCircleIconLight,
  caretDown: CaretDown,
  userHeadset: UserHeadset,
  longArrow: LongArrow,
  link: Link,
  mobilePhone: MobilePhone,
  phone: Phone,
  phoneOutline: PhoneOutline,
  medal: MedalIcon,
  medalSolid: MedalSolidIcon,
  homeHeart: HomeHeartIcon,
  sortIcon: SortIcon,
  sortIdle: SortIdle,
  sortAsc: SortASC,
  sortDsc: SortDSC,
  moneyCheck: MoneyCheckIcon,
  warningCircleFilled: WarningCircleFilled,
  successCircleFilled: SuccessCircleFilled,
  mapMarker: MapMarker,
  mapMarkerSolid: MapMarkerSolid,
  bedroom: Bedroom,
  bathroom: Bathroom,
  kitchen: Kitchen,
  livingRoom: LivingRoom,
  propertyExterior: PropertyExterior,
  graphicRise: GraphicRiseIcon,
  offer: OfferIcon,
  lock: LockIcon,
  lockSolid: LockSolidIcon,
  arrowLongRight: ArrowLongRightIcon,
  arrowRight: ArrowRight,
  clock: ClockIcon,
  dollarCircle: DollarCircle,
  dollar: Dollar,
  editDocument: EditDocument,
  referralsIcon: ReferralsIcon,
  referralsBlocked: ReferralsBlocked,
  bank: Bank,
  userCard: UserCard,
  userCardSolid: UserCardSolid,
  trash: Trash,
  awardSolidIcon: AwardSolidIcon,
  frontOfProperty: FrontOfProperty,
  outdoor: Outdoor,
  uniqueFeatures: UniqueFeatures,
  addressBook: AddressBook,
};

export type IconTypeProp = keyof typeof ICONS;

interface IconProps {
  size?: number;
  fill?: Colors | string;
  color?: Colors | string;
  type: IconTypeProp;
  role?: string;
  solid?: boolean;
  contrast?: boolean;
  flip?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  'data-test'?: string;
}

export type IconType = IconProps &
  DisplayProps &
  SpacingProps &
  SizingProps &
  FlexboxProps &
  PositionsProps;

export const Icon = forwardRef<HTMLDivElement, IconType>(
  (
    {
      size = 20,
      type,
      fill = 'black',
      contrast,
      solid,
      color = 'darkBlue',
      'data-test': dataTest,
      ...otherProps
    },
    forwardedRef
  ) => {
    const IconChosen = ICONS[type];

    if (typeof IconChosen === 'undefined') {
      throw new Error(`Icon ${type} not found.`);
    }

    return (
      <IconWrapper
        size={size}
        fill={theme.colors[fill as Colors] || fill}
        contrast={contrast}
        ref={forwardedRef}
        data-test={dataTest}
        color={theme.colors[color as Colors] || color}
        {...otherProps}
      >
        <IconChosen solid={solid} fill={fill} />
      </IconWrapper>
    );
  }
);

Icon.displayName = 'Icon';
