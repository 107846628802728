import * as Sentry from '@sentry/nextjs';

import React, { useState } from 'react';

import { signOut as nextSignOut } from 'next-auth/react';
import { useRouter } from 'next/router';

import { Box, Icon, Paragraph } from '@hl-portals/ui';

import { TOKENIZED_PAGES, hapiLogoutPath } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useUser } from '../../../contexts/Auth';
import { renderNavbar } from '../../../helpers/renderNavbar';
import useQueryString from '../../../hooks/useQueryString';
import HeaderLogo from '../HeaderLogo';
import Navbar from '../Navbar';
import NavbarSearch from '../NavbarSearch';
import AlertBanner from './AlertBanner';
import {
  AdjustedBodyStyle,
  HeaderContainer,
  HeaderMenuToggle,
  HeaderWrapper,
  ImpersonationHeader,
  UnimpersonateButton,
} from './styles';

const Header = (): React.ReactElement => {
  const { user, isAuthenticated } = useUser();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const queryParams = useQueryString();

  const onMenuToggle = () => {
    setMenuOpen(!menuOpen);
    setSearchOpen(false);
  };

  const onSearchToggle = (isOpen: boolean) => {
    setSearchOpen(isOpen);
  };

  const unimpersonate = async () => {
    try {
      await enhancedAxios({
        url: hapiLogoutPath,
        method: 'DELETE',
        withCredentials: true,
      }).then(async (data) => {
        await nextSignOut({ redirect: false });

        if (data.return_url) {
          window.location.href = data.return_url;
        } else {
          router.replace('/dashboard');
        }
      });
    } catch (error) {
      console.error('Failed to unimpersonate!', error);
      Sentry.captureException(error);
    }
  };

  const isTokenizedLink =
    typeof queryParams?.token !== 'undefined' &&
    TOKENIZED_PAGES.includes(router.pathname);

  const isEliteAgent = Boolean(user?.agent?.elite_agent);
  const isImpersonating = Boolean(user?.impersonated);
  const name = user?.first_name + ' ' + user?.last_name;

  return (
    <>
      <HeaderWrapper
        isElite={isEliteAgent}
        isOpen={menuOpen}
        isSearchOpen={searchOpen}
        data-test="header-wrapper"
      >
        {isImpersonating && (
          <ImpersonationHeader>
            <Paragraph color="white" variant="text">
              Impersonating {name}
            </Paragraph>
            <UnimpersonateButton onClick={unimpersonate}>
              Stop Impersonating
            </UnimpersonateButton>
          </ImpersonationHeader>
        )}
        <AdjustedBodyStyle isSearchOpen={searchOpen} />
        <HeaderContainer
          data-test="header-container"
          flexWrap={{
            xs: 'wrap',
            md: 'nowrap',
          }}
          width={{
            xs: 'auto',
            md: '1280px',
          }}
        >
          <Box alignItems="center">
            <HeaderLogo isElite={isEliteAgent} />
            {isAuthenticated && (
              <NavbarSearch
                isSearchOpen={searchOpen}
                onSearchToggle={onSearchToggle}
              />
            )}
          </Box>
          {isAuthenticated && (
            <>
              <Box
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                flexGrow={1}
                flexWrap="nowrap"
                display={{ xs: 'flex', md: 'none' }}
              >
                <Icon
                  display={{ xs: menuOpen ? 'none' : 'block', md: 'none' }}
                  type="search"
                  contrast={isEliteAgent}
                  onClick={() => onSearchToggle(!searchOpen)}
                  role="button"
                  data-test="header-search-icon"
                />
                <HeaderMenuToggle
                  isOpen={menuOpen}
                  isElite={isEliteAgent}
                  onClick={onMenuToggle}
                  aria-pressed="false"
                  aria-label="Navigation Button"
                  data-test="header-navbar-button"
                >
                  <span />
                </HeaderMenuToggle>
              </Box>
              {!isTokenizedLink && (
                <Navbar
                  isOpen={menuOpen}
                  isSearchOpen={searchOpen}
                  items={renderNavbar(
                    user?.agent?.marketplace_program_agents ?? []
                  )}
                  isElite={isEliteAgent}
                  menuClose={onMenuToggle}
                />
              )}
            </>
          )}
        </HeaderContainer>
      </HeaderWrapper>
      {isAuthenticated && <AlertBanner isElite={isEliteAgent} />}
    </>
  );
};

export default Header;
