import Link from 'next/link';
import styled, { css } from 'styled-components';

import { Box, Icon } from '@hl-portals/ui';

import { INavbar } from '../../../helpers/renderNavbar';
import useMessagesUnreadCount from '../../../hooks/useMessagesUnreadCount';

const MessageCountBadge = styled.div`
  ${({ theme }) => css`
    position: absolute;
    height: 0.875rem;
    line-height: 0.875rem;
    padding: 0 0.25rem;
    top: 1rem;
    left: 0.875rem;
    background-color: ${theme.colors.cadmiumRed};
    color: ${theme.colors.white};
    border-radius: 0.75rem;
    font-size: 0.625rem;
    font-weight: 600;
  `}
`;

interface NavbarMessageProps {
  item: INavbar;
  menuClose?: () => void;
  active: boolean;
  isLight: boolean;
}

const NavbarMessage = ({
  item,
  menuClose,
  active,
  isLight,
}: NavbarMessageProps): React.ReactElement => {
  const { data } = useMessagesUnreadCount();

  const hasMessage = !!data?.data?.attributes?.unread_communications_count;
  const url = typeof item.url === 'function' ? item.url() : item.url;

  return (
    <>
      <Link href={url}>
        <Box
          as="a"
          display={{ xs: 'none', md: 'block' }}
          justifyContent="center"
          alignItems="center"
          date-test="navbar-message"
        >
          <Icon
            type="message"
            solid={false}
            data-test="navbar-message-icon"
            contrast={isLight}
          />
          {hasMessage && (
            <MessageCountBadge data-test='message-count-badge'>
              {data.data.attributes.unread_communications_count}
            </MessageCountBadge>
          )}
        </Box>
      </Link>

      <Link href={url}>
        <Box
          as="a"
          display={{ xs: 'block', md: 'none' }}
          date-test="navbar-message"
          onClick={() => menuClose()}
          style={{ fontWeight: active ? 'bold' : 'lighter' }}
        >
          <span>{item.text}</span>
        </Box>
      </Link>
    </>
  );
};

export default NavbarMessage;
