import { ReactNode, useEffect, useState } from 'react';

import { Box, BoxTypes, Dialog, ICONS, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import {
  FileLoading,
  FilePicker,
  FileProgressIcon,
  UploadedFiles,
} from './components';
import { FileInputWrapper } from './styles';

export type FileInputProps = {
  onFilesChange?: (files: FileList) => void;
  selectedFiles?: Array<File>;
  label?: ReactNode;
  loading?: boolean;
  loadingText?: string;
  accept?: Array<string>;
  progress?: number;
  multipleUpload?: boolean;
  fileIcon?: ReactNode;
  uploadButtonSize?: 'medium' | 'large';
  uploadButtonColor?: 'primary' | 'secondary';
  uploadButtonText?: string;
  formatSizeDescription?: string;
  disabled?: boolean;
} & BoxTypes;

type UploadState = 'uploading' | 'uploaded' | 'selection';

export const FileInput = ({
  onFilesChange = () => {},
  multipleUpload = false,
  selectedFiles,
  label,
  loading = false,
  loadingText = 'Uploading...',
  accept,
  progress,
  fileIcon,
  uploadButtonSize,
  uploadButtonColor,
  uploadButtonText,
  formatSizeDescription,
  disabled,
  ...rest
}: FileInputProps): React.ReactElement => {
  const [files, setFiles] = useState(selectedFiles || []);
  const [active, setActive] = useState(false);
  const { openModal } = useModal();

  // allow input to be controlled, if desired
  useEffect(() => selectedFiles && setFiles(selectedFiles), [selectedFiles]);

  const onDragEnter = () => !loading && setActive(true);
  const onDragLeave = () => !loading && setActive(false);
  const fileChange = (newFiles: any) => {
    onFilesChange(newFiles);
    setFiles(Array.from(newFiles));
  };

  const openDialogModal = (messages: Array<React.ReactNode | string>): void =>
    openModal(
      <Dialog
        icon="document-error"
        buttonsPosition="right"
        buttons={[
          {
            action: ({ closeDialog }) => closeDialog(),
            text: 'Ok',
          },
        ]}
      >
        <Paragraph variant="heading-3" color="darkBlue" mb="12px">
          Oops!
        </Paragraph>
        {messages.map((message, index) => (
          <Paragraph
            variant="text"
            color="coolGray1"
            mb={index !== messages.length - 1 && '4px'}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {message}
          </Paragraph>
        ))}
      </Dialog>
    );

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (loading) {
      return;
    }

    const filesDragged = Array.from(e.dataTransfer.files);
    if (filesDragged.length > 1 && !multipleUpload) {
      openDialogModal([
        'Multiple documents are not accepted.',
        'Please upload only a single document.',
      ]);
      return;
    }

    const acceptedFiles: Array<File> = [];
    filesDragged.forEach((file) => {
      const [ext] = file.name.split('.').reverse();
      if (accept?.includes(ext)) {
        acceptedFiles.push(file);
      }
    });

    if (acceptedFiles.length !== filesDragged.length) {
      const message =
        filesDragged.length === 1
          ? 'This file format is not accepted.'
          : 'One or more files do not have the accepted format.';
      openDialogModal([
        message,
        <>
          Please upload only <strong>.{accept?.join(', .')}</strong> files.
        </>,
      ]);
      return;
    }

    fileChange(acceptedFiles);
    setActive(false);
  };

  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!loading) {
      setActive(true);
    }
  };

  const resetFiles = () => fileChange([]);

  const getUploadState = ({
    isLoading,
    filesNumber,
    isMultipleUpload,
  }: {
    isLoading: boolean;
    filesNumber: number;
    isMultipleUpload: boolean;
  }): UploadState => {
    if (isLoading) {
      return 'uploading';
    }

    if (filesNumber && !isMultipleUpload) {
      return 'uploaded';
    }

    return 'selection';
  };

  const icons: { [key in UploadState]: keyof typeof ICONS } = {
    selection: 'cloudUpload',
    uploading: 'uploadingDocument',
    uploaded: 'uploadedDocument',
  };

  const uploadState = getUploadState({
    isLoading: loading,
    isMultipleUpload: multipleUpload,
    filesNumber: files.length,
  });

  return (
    <FileInputWrapper
      active={active}
      alignItems="center"
      {...(disabled
        ? {}
        : {
            onDragEnter: onDragEnter,
            onDragLeave: onDragLeave,
            onDragOver: onDragOver,
            onDrop: onDrop,
          })}
      data-test="file-dropzone"
      cursor={uploadState === 'uploading' ? 'no-drop' : 'default'}
      disabled={disabled}
      {...rest}
    >
      <Box
        p="1rem"
        m="0 auto"
        flexDirection="column"
        alignItems="center"
        width="100%"
        as="label"
        cursor={uploadState === 'uploading' ? 'no-drop' : 'default'}
      >
        <Box alignItems="center" gap="8px">
          {fileIcon || (
            <FileProgressIcon
              type={icons[uploadState]}
              ml={files.length && 1}
            />
          )}
          {uploadState === 'selection' && (
            <>
              <Paragraph variant="text-small">{label}</Paragraph>
              <FilePicker
                label={label}
                accept={accept}
                fileChange={fileChange}
                multipleUpload={multipleUpload}
                uploadButtonSize={uploadButtonSize}
                uploadButtonColor={uploadButtonColor}
                uploadButtonText={uploadButtonText}
                disabled={disabled}
              />
            </>
          )}
        </Box>
        {formatSizeDescription && (
          <Paragraph
            variant="text-small"
            textAlign="center"
            mt="16px"
            color="coolGray1"
          >
            {formatSizeDescription}
          </Paragraph>
        )}
        {uploadState === 'uploaded' && (
          <UploadedFiles files={files} resetFiles={resetFiles} />
        )}
        {uploadState === 'uploading' && (
          <FileLoading labelText={loadingText} progress={progress} />
        )}
      </Box>
    </FileInputWrapper>
  );
};
