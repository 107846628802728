import styled from 'styled-components';

import { slideInFromTop } from '@hl-portals/helpers';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import Tooltip from '../Tooltip';
import { Paragraph, ParagraphProps } from '../Typography';

export interface InputGroupProps {
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  note?: string;
  tooltip?: string;
  error?: string;
  required?: boolean;
  optional?: boolean;
  noteProps?: ParagraphProps;
  'error-data-test'?: string;
}

const AnimatedParagraph = styled(Paragraph)`
  animation: 0.3s ease 0s 1 normal ${slideInFromTop};
`;

const InputGroup = ({
  children,
  label,
  labelProps = {},
  note,
  error,
  optional,
  required,
  tooltip,
  noteProps,
  'error-data-test': errorDataTest,
  ...extraProps
}: InputGroupProps & BoxTypes): React.ReactElement => (
  <Box flexDirection="column" position="relative" {...extraProps}>
    <Box alignItems="center" mb="4px">
      {label &&
        (typeof label === 'string' ? (
          <Paragraph
            variant="text-small"
            color="darkBlue"
            display="flex"
            {...labelProps}
          >
            {label} {required && '*'}{' '}
          </Paragraph>
        ) : (
          <>
            {label} {required && '*'}{' '}
          </>
        ))}
      {tooltip && (
        <Box ml="8px">
          <Tooltip message={tooltip}>
            <Icon fill="coolGray3" type="questionCircle" size={16} />
          </Tooltip>
        </Box>
      )}
      {note && (
        <Paragraph
          variant="text-small"
          color="coolGray2"
          fontStyle="italic"
          ml="5px"
          {...noteProps}
        >
          {note}
        </Paragraph>
      )}
      {optional && (
        <Paragraph
          variant="text-small"
          color="coolGray2"
          ml="5px"
          {...noteProps}
        >
          (Optional)
        </Paragraph>
      )}
    </Box>

    {children}

    {error && (
      <Box mt="8px">
        <AnimatedParagraph
          variant="text-small"
          color="cadmiumRed"
          data-test={errorDataTest}
        >
          {error}
        </AnimatedParagraph>
      </Box>
    )}
  </Box>
);
export default InputGroup;
