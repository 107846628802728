import {
  DatePicker as DatePickerComponent,
  StaticDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useState } from 'react';

import { INVALID_DATE } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';
import { Input } from '../Input';

export type DatePickerProps = {
  /**
   * The date will either be returned as a valid ISO date string,
   * or with 'Invalid Date'.
   */
  onDateSelect: (date: string) => void;
  /**
   * Date value as ISO date string
   */
  value: string;
  /**
   * Minimum date in YYYY-MM-DD format. If left blank, then it will default to the current date.
   */
  minDate?: string;
  /**
   * Maximum date in YYYY-MM-DD format. If left blank, then it will default to the current date.
   */
  maxDate?: string;
  disabled?: boolean;
  error?: boolean;
  width?: string | number;
  staticView?: boolean;
  'data-test'?: string;
  disableWeekends?: boolean;
  disableDates?: dayjs.Dayjs[];
} & BoxTypes;

const DatePicker = ({
  onDateSelect,
  value,
  minDate,
  maxDate,
  disabled,
  error,
  width,
  staticView,
  'data-test': dataTest,
  disableWeekends,
  disableDates,
  ...otherProps
}: DatePickerProps): React.ReactElement => {
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const Picker = staticView ? StaticDatePicker : DatePickerComponent;

  const onChange = (date: any) => {
    if (date?.$D) {
      const minimumDate = dayjs(minDate);
      const maximumDate = dayjs(maxDate);
      if (
        dayjs(date.$d).isBefore(minimumDate, 'day') ||
        (maxDate && dayjs(date.$d).isAfter(maximumDate, 'day'))
      ) {
        setInvalidDate(true);
        onDateSelect(INVALID_DATE);
      } else {
        setInvalidDate(false);
        onDateSelect(dayjs(date.$d).toISOString());
      }
    } else if (date === null) {
      setInvalidDate(false);
      onDateSelect('');
    } else {
      setInvalidDate(true);
      onDateSelect(INVALID_DATE);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={'en-US'}>
      <Picker
        inputFormat="MM/DD/YYYY"
        views={['year', 'month', 'day']}
        minDate={dayjs(minDate)}
        {...(maxDate && {
          maxDate: dayjs(maxDate),
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box
            position="relative"
            alignItems="center"
            width={width}
            {...otherProps}
          >
            <Input
              ref={inputRef}
              {...inputProps}
              disabled={disabled}
              isError={error || invalidDate}
              width={width}
              data-test={dataTest || 'date-picker-input'}
            />
            {!disabled && (
              <Box position="absolute" right="16px">
                {InputProps?.endAdornment}
              </Box>
            )}
          </Box>
        )}
        onChange={onChange}
        value={value}
        disabled={disabled}
        shouldDisableDate={(date) => {
          const dayOfWeek = date.day();
          const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
          const isDisabledDate =
            disableDates?.some(
              (d) => d.format('DD/MM/YYYY') === date.format('DD/MM/YYYY')
            ) || false;

          if (disableWeekends && isWeekend) return true;
          if (disableDates && isDisabledDate) return true;
          return false;
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
