import { SizingProps, SpacingProps } from '@mui/system';

import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { colors } from '@hl-portals/constants';

import { useScreenSize } from '@hl-portals/hooks';

import { Box } from '../Box';
import { FileInput, FileInputProps } from '../FileInput';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';
import { useFileUpload } from './hooks/useFileUpload';
import { UploadedFilesList } from './styles';
import { FileUploadType, ISelectedFile } from './types';

const DocIcon = () => (
  <svg
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2524_67646)">
      <path
        d="M13.3333 3.92619e-06H1.25C1.08626 -0.000406969 0.924043 0.0314377 0.772607 0.0937197C0.621172 0.156002 0.483487 0.247501 0.367414 0.362994C0.25134 0.478487 0.159152 0.615712 0.0961115 0.766833C0.0330712 0.917954 0.000413793 1.08001 3.92619e-06 1.24375V25.4167C-0.000406969 25.5804 0.0314377 25.7426 0.0937197 25.8941C0.156002 26.0455 0.247501 26.1832 0.362994 26.2993C0.478487 26.4153 0.615712 26.5075 0.766833 26.5706C0.917954 26.6336 1.08001 26.6663 1.24375 26.6667H18.75C18.9138 26.6671 19.076 26.6352 19.2274 26.5729C19.3788 26.5107 19.5165 26.4192 19.6326 26.3037C19.7487 26.1882 19.8409 26.051 19.9039 25.8998C19.9669 25.7487 19.9996 25.5867 20 25.4229V6.66667H14.1667C13.9457 6.66667 13.7337 6.57887 13.5774 6.42259C13.4211 6.26631 13.3333 6.05435 13.3333 5.83334V3.92619e-06Z"
        fill="#B6E2FF"
      />
      <path
        d="M20.0002 6.34896V6.66667H14.1668C13.9458 6.66667 13.7339 6.57888 13.5776 6.42259C13.4213 6.26631 13.3335 6.05435 13.3335 5.83334V3.7587e-06H13.6512C13.8155 -0.00039915 13.9783 0.0315937 14.1302 0.0941524C14.2821 0.156711 14.4202 0.248608 14.5366 0.364587L19.6356 5.46876C19.7512 5.58435 19.8429 5.72157 19.9054 5.8726C19.968 6.02363 20.0002 6.18549 20.0002 6.34896Z"
        fill="#1192E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2524_67646">
        <rect width="20" height="26.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

type FileUploadListProps = {
  selectedFilesRef: any;
  fileListPosition: any;
  fileType: any;
  selectedFiles: ISelectedFile[];
  getFileInfo: any;
  onRemoveFile: any;
};

const FileUploadList = ({
  selectedFilesRef,
  fileListPosition,
  fileType,
  selectedFiles,
  getFileInfo,
  onRemoveFile,
}: FileUploadListProps) => {
  return (
    <UploadedFilesList
      ref={selectedFilesRef}
      height={{
        xs: 1,
        md: '100%',
      }}
      width={{
        xs: 1,
        md: '100%',
      }}
      overflowY={{
        xs: 'visible',
      }}
      flexDirection="column"
      m={{
        xs: '24px 0 0',
        md: fileListPosition === 'right' ? '0 0 0 16px' : '24px 0 0',
      }}
      data-test="fileUploader-uploadedFiles"
    >
      <Paragraph color="coolGray2" variant="text" mb="16px">
        {`Selected ${fileType === 'document' ? 'files' : 'photos'} (${
          selectedFiles?.length
        })`}
      </Paragraph>

      {selectedFiles.map((file, index) => {
        const { name, size, id, isFileUploaded } = file;
        const [fileName, fileExtension] = name.split('.');

        return (
          <Box
            key={`${id || Math.floor(Math.random() * 1000)}-${fileName}`}
            width={{ xs: 1, md: 'unset' }}
            justifyContent="space-between"
            alignItems="center"
            mb="12px"
            p="12px 16px"
            border={`1px solid ${colors.coolGray5}`}
            borderRadius="12px"
            data-test="fileUploader-uploadedFiles-file"
          >
            <Box>
              <Box width="20px" alignSelf="center">
                <DocIcon />
              </Box>
              <Box width={1} flexDirection="column" ml="12px">
                <Paragraph
                  variant="text-bold"
                  color="darkBlue"
                  width={1}
                  maxWidth={{
                    xs: '175px',
                    sm: '400px',
                    md: '200px',
                  }}
                  mb="2px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  title={fileName}
                >
                  {fileName}
                </Paragraph>
                <Paragraph variant="text-small" color="coolGray2">
                  {getFileInfo(fileName, size)}
                </Paragraph>
              </Box>
            </Box>

            <Box alignItems="center" gap="20px">
              <Paragraph variant="text-small" color="#72757D">
                {Math.ceil(size / 1000)} KB {fileExtension?.toUpperCase()}
              </Paragraph>

              {!isFileUploaded && (
                <Box top="12px" right="12px" cursor="pointer">
                  <Icon
                    type="clearButton"
                    size={24}
                    onClick={() => onRemoveFile(index)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </UploadedFilesList>
  );
};

type FileUploaderProps = {
  /**
   * Specify whether document(s) or image(s) will be uploaded
   */
  fileType: FileUploadType;
  /**
   * Returns an array of the selected documents
   */
  onFileChange?: (result: Array<File>) => void;
  /**
   * (Only available when the 'multipleUpload' prop is enabled) Returns an updated array of the selected documents when a single file is removed.
   */
  onFileRemove?: (result: Array<File>, index: number) => void;
  /**
   * Error handler when something wrong happens.
   */
  onError?: (err: string) => void;
  /**
   * Error message
   */
  error?: string;
  /**
   * Lead for the file(s) to be attached to during STEP 3
   */
  leadId?: string;
  /**
   * Only perform file upload to S3, and return the file key(s) from S3.
   * Otherwise without this prop, the "process and create" step will run.
   */
  ignoreProcessAndCreateStep?: boolean;
  /**
   * Overrides the default upload behavior to a custom function
   * that receives the files from the input
   */
  customHandler?: (files: FileList) => void | Promise<void>;
  /**
   * The maximum file size in megabytes for the files to be uploaded. It throws an
   * error if use attempt to upload a file with size that exceeds maxFileSize
   */
  maxFileSize?: number;
  /**
   * Where the "Selected files" list will be displayed, relative to the file upload area
   */
  fileListPosition?: 'right' | 'bottom' | 'over';
  /**
   * Scroll into the "Selected files" list so that it's visible after file(s) upload
   */
  scrollIntoSelectedFilesView?: boolean;
  /**
   * Disable the file upload functionality
   */
  disabled?: boolean;
  /**
   * Save only unique files
   */
  uniqueOnly?: boolean;
  /**
   * Hide dropzone when in mobile view
   */
  hideOnMobile?: boolean;
  source?: string;
} & FileInputProps &
  SizingProps &
  SpacingProps;

const FileUploader = ({
  fileType,
  onFileChange,
  onFileRemove,
  accept,
  multipleUpload,
  leadId,
  ignoreProcessAndCreateStep,
  customHandler,
  maxFileSize,
  onError,
  error,
  fileListPosition = 'right',
  scrollIntoSelectedFilesView,
  disabled,
  uniqueOnly,
  hideOnMobile,
  source,
  ...rest
}: FileUploaderProps): React.ReactElement => {
  const selectedFilesRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenSize();

  const { loading, percentProgress, selectedFiles, setSelectedFiles } =
    useFileUpload();

  useEffect(() => {
    if (
      scrollIntoSelectedFilesView &&
      selectedFiles &&
      selectedFilesRef?.current
    ) {
      selectedFilesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoSelectedFilesView, selectedFilesRef, selectedFiles]);

  const validateFilesSizes = (files: Array<File>): boolean => {
    if (
      maxFileSize &&
      Array.from(files).some((f) => f.size > maxFileSize * 1000000)
    ) {
      toast(
        `The max size of each file cannot be larger than ${maxFileSize} MB`,
        {
          type: 'error',
        }
      );
      return false;
    }
    return true;
  };

  const onRemoveFile = (index: number) => {
    if (typeof index !== 'number' || selectedFiles?.length === 0) return;

    const newArray = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newArray);

    if (onFileRemove) {
      onFileRemove(newArray, index);
    }
  };

  const onAddFiles = (files: ISelectedFile[]) => {
    setSelectedFiles((prev) => {
      if (!uniqueOnly) return [...prev, ...files];

      return [...prev, ...files].reduce((uniqueList, file) => {
        const { name, size } = file;
        const found = uniqueList.find(
          (uf) => uf.name === name && uf.size === size
        );

        if (found) return uniqueList;
        return [...uniqueList, file];
      }, [] as ISelectedFile[]);
    });
  };

  const handleOnFilesChange = async (files: FileList) => {
    const fileList: Array<ISelectedFile> = Array.from(files);

    if (maxFileSize) {
      const isValidFileSize = validateFilesSizes(fileList);
      if (!isValidFileSize) {
        return;
      }
    }

    if (fileType === 'image') {
      for (let i = 0, f; (f = files[i]); i++) {
        if (!f.type.match('image.*')) {
          continue;
        }

        const reader = new FileReader();

        reader.onload = (function () {
          return function (e) {
            fileList[i].thumbnail = String(e?.target?.result) || '';
            onAddFiles(fileList);
          };
        })();

        reader.readAsDataURL(f);
      }
    } else {
      onAddFiles(fileList);
    }

    if (onFileChange) {
      onFileChange(fileList);
    }
  };

  const getFileInfo = (name: string, size: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fileSize = Number(size / 1000000).toFixed(2) + ' MB';
    const FILE_TYPE = String(name.split('.').pop()).toUpperCase();

    // TODO: Return `${fileSize} ${fileType}`
    // once the backend is responding with a valid file size

    return FILE_TYPE;
  };

  const fileListProps = {
    selectedFilesRef,
    fileListPosition,
    fileType,
    selectedFiles,
    getFileInfo,
    onRemoveFile,
    source,
  };

  return (
    <Box
      width={{
        xs: 1,
        md: '100%',
      }}
      flexDirection={{
        xs: 'column',
        md: fileListPosition === 'right' ? 'row' : 'column',
      }}
      {...rest}
    >
      {fileListPosition === 'over' && !!selectedFiles.length ? (
        <FileUploadList {...fileListProps} />
      ) : (
        <FileInput
          loading={loading}
          onFilesChange={customHandler ? customHandler : handleOnFilesChange}
          progress={percentProgress * 100}
          accept={accept}
          multipleUpload={multipleUpload}
          maxWidth={{ xs: 1, md: '600px' }}
          uploadButtonColor="secondary"
          disabled={disabled}
          display={isMobile && hideOnMobile ? 'none' : 'flex'}
          alignItems="center"
          mb="32px"
          {...rest}
        />
      )}
      {multipleUpload && !!selectedFiles?.length && (
        <FileUploadList {...fileListProps} />
      )}
      {error && (
        <Paragraph variant="text-small" color="cadmiumRed">
          {error}
        </Paragraph>
      )}
    </Box>
  );
};

export default FileUploader;
