import { useState } from 'react';

import Link from 'next/link';

import { Avatar, Box, Icon, Paragraph } from '@hl-portals/ui';

import { useHandleClickOutside } from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';
import { INavbar } from '../../../helpers/renderNavbar';
import { NavbarItem, NavbarList } from '../Navbar/styles';
import {
  DropdownButton,
  NavbarDropdownInner,
  NavbarDropdownWrapper,
  NavbarProfile,
} from './styles';

type IDropdownUser = {
  firstName: string;
  lastName: string;
  email: string;
};

interface NavbarDropdownProps {
  item: INavbar;
  menuClose?: () => void;
  isElite: boolean;
  user: IDropdownUser;
}

const NavbarDropdown = ({
  item,
  menuClose,
  isElite,
}: NavbarDropdownProps): React.ReactElement => {
  const { user, featureFlags } = useUser();
  const [state, setState] = useState(0);

  const navRef = useHandleClickOutside<HTMLElement>({
    onClickOutside: () => setState(0),
  });

  const onOpenDropdown = (id: number): void => {
    setState(id === state ? 0 : id);
  };

  const checkPrograms = ({
    featureFlags: flags,
    somePrograms,
    everyPrograms,
  }) =>
    (typeof everyPrograms === 'undefined' &&
      typeof somePrograms === 'undefined') ||
    somePrograms?.some((program) => Boolean(flags[program])) ||
    everyPrograms?.every((program) => Boolean(flags[program]));

  const onLinkClick = () => {
    menuClose();
    setState(0);
  };

  return (
    <>
      <DropdownButton
        isElite={isElite}
        role="button"
        tabIndex={0}
        onClick={() => onOpenDropdown(item.id)}
        onKeyPress={() => onOpenDropdown(item.id)}
        data-test="navbar-dropdown-button"
      >
        <Icon size={20} type="user" />
        <span>{item.text}</span>
      </DropdownButton>
      <NavbarDropdownWrapper ref={navRef} isOpen={state === item.id}>
        <NavbarList data-test="navbar-dropdown-list">
          <NavbarProfile>
            <Avatar
              size="big"
              type="normal"
              initials={user.first_name[0]}
              mr="10px"
            />
            <Box
              flexDirection="column"
              data-test="navbar-profile-user-name"
              overflowY="hidden"
            >
              <Paragraph
                variant="heading-5"
                color="darkBlue"
                mb="2px"
                truncate
                title={`${user.first_name} ${user.last_name}`}
              >
                {user.first_name} {user.last_name}
              </Paragraph>
              <Paragraph
                variant="text-xsmall"
                color="coolGray2"
                truncate
                title={user.email}
              >
                {user.email}
              </Paragraph>
            </Box>
          </NavbarProfile>
          {item.items.map((dropdownItem) => {
            if (
              !checkPrograms({
                featureFlags,
                somePrograms: dropdownItem.someProgramKey,
                everyPrograms: dropdownItem.everyProgramKey,
              })
            ) {
              return null;
            }

            if (dropdownItem.type === 'dropdown') {
              return (
                <NavbarDropdownInner
                  key={dropdownItem.id}
                  item={dropdownItem}
                />
              );
            }

            return (
              <NavbarItem
                key={dropdownItem.id}
                data-test="navbar-dropdown-item"
              >
                {dropdownItem.type === 'link' && (
                  <Link
                    href={
                      typeof dropdownItem.url === 'function'
                        ? dropdownItem.url()
                        : dropdownItem.url ?? ''
                    }
                    passHref
                  >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a
                      onClick={onLinkClick}
                      target={dropdownItem.openInNewTab ? '_target' : '_self'}
                    >
                      {dropdownItem.text}
                    </a>
                  </Link>
                )}
              </NavbarItem>
            );
          })}
          <NavbarItem data-test="navbar-dropdown-item" withBorderTop>
            <Link href="/logout" passHref>
              <a onClick={onLinkClick}>Sign Out</a>
            </Link>
          </NavbarItem>
        </NavbarList>
      </NavbarDropdownWrapper>
    </>
  );
};

export default NavbarDropdown;
